<template>
  <div style="background-color: #fff;position: relative;height:100vh;">
    <div style="font-size:16px;color:#333;position: fixed;top:30%;right:0;left:0;margin:auto;" class="loging">
      <img src="http://exshow.oss-cn-shanghai.aliyuncs.com/exshow/imgs/202009/f6458e716e914e6eab1b1c37e94fa15d.gif">
      <p>努力加载中，请稍候..</p>
      <!-- 参展获取更多商机auth页面 -->
    </div>
    <div style="position: fixed;bottom: 16%;width:100%">
      <div>
        <img src="https://img.zhanshangxiu.com/showonline/file/202103/20210325/1f0ae8b7d0d348f9bc52c53eda85c5cf.png" alt="">
        <!-- <img src="https://exshow.oss-cn-shanghai.aliyuncs.com/exshow/imgs/202009/36b5311813a4434f8c410815fa862812.png" >   灰色不渐变展商秀logo-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "auth",
  data () {
    return {};
  },
  computed: {
    ...mapGetters({
      redirectUrl: "getRedirectUrl",
    })
  },
  created () {
    const redirectUrl = this.redirectUrl;
    localStorage.clear();
    this.$store.commit('setRedirectUrl', redirectUrl);
    this.authenticateOidc().then(c => {
      console.log('authenticateOidc: ', c);
    });
  },
  methods: {
    ...mapActions({
      authenticateOidc: "authenticateOidc"
    })
  }
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.loging {
  img {
    width: 120px;
    height: 120px;
  }
}
div {
  text-align: center;
  img {
    display: inline-block;
    // height: 30px;
    max-width: 140px;
  }
}
</style>
